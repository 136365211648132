// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bD_mc";
export var caseContactFormSection = "bD_k5";
export var caseSolutionRing = "bD_md";
export var caseStudyBackground__bgColor = "bD_kW";
export var caseStudyBackground__lineColor = "bD_kX";
export var caseStudyCta__bgColor = "bD_lX";
export var caseStudyHead = "bD_k7";
export var caseStudyHead__imageWrapper = "bD_kV";
export var caseStudyProjectsSection = "bD_k6";
export var caseStudyQuote__dark = "bD_l8";
export var caseStudyQuote__light = "bD_l9";
export var caseStudySolution__ringOne = "bD_mb";
export var caseStudySolution__ringTwo = "bD_k1";
export var caseStudyTechSection = "bD_lf";
export var caseStudyVideo__ring = "bD_k8";
export var caseStudy__bgDark = "bD_kT";
export var caseStudy__bgLight = "bD_kS";